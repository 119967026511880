import React from "react";

import '../css/tickets.css'

var SECRET = 'https://trap-house.qtickets.ru/event/138099?base_color=940000'
var CODE = 'https://sochi.qtickets.events/135589-code-1080-bolshoe-vystuplenie'
var FEST = 'https://sochi.qtickets.events/129411-south-festival-4-artista-predprodazha'

function Button(props) {
  return(
    <a target={"_blank"} rel="noreferrer" href={props.link} className="ticketBtn">{props.text}</a>
  );
}

function Tickets() {
  return(
    <div className="containerTickets" id="ticket">
      <h1 className="ticketsToBuy">Чтобы купить билет</h1>
      <h1 className="ticketsChoise">Выбери мероприятие</h1>
      <div className="ticketsContainer">
        <Button link={SECRET} text="White Party"></Button>
        <Button link={CODE} text="CODE80"></Button>
        <Button link={FEST} text="FESTIVAL"></Button>
      </div>
      <p>*Если кнопка не работает, то билеты приобретаются на входе</p>
    </div>
  )
}

export default Tickets;